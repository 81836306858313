/** 搜索医院列表 */
export interface HospitalListItem {
  /** 医院省份 */
  placeCode?: string;
  /** 医院名称 */
  name?: string;
  /** 医院别称 */
  alias?: string;
  /** 医院级别 */
  level?: number;
  /** 医院分类 */
  catalog?: number;
  /* 目标任务组id */
  targetTaskGroupId?: string;
  pageNum?: number;
  pageSize?: number;
}
/** 新增医院 */
export interface AddHospitalItem {
  /** 医院名称 */
  name: string;
  /** 医院别称 */
  alias?: string;
  /** 医院级别 */
  level?: number;
  /** 医院分类 */
  catalog?: number;
  /** 医院简介 */
  profile?: string;
  /** 通过地图获取医院名称 */
  amapName: string;
  /** 医院地址 */
  address: string;
  /** 经度 */
  longitude: string;
  /** 纬度 */
  latitude: string;
  /** 营业执照 */
  businessLicense: string;
  /** 地区 code */
  placeCode: string;
  /** 省市区 */
  district: string;
  /** 是否允许上传原始视频 */
  dataUploadCloud: boolean;
  /** 是否开启临床实验 */
  isClinical: boolean;
}
export interface EditHospitalItem {
  /** 医院名称 */
  name: string;
  /** 医院别称 */
  alias?: string;
  /** 医院级别 */
  level?: number;
  /** 医院分类 */
  catalog?: number;
  /** 医院简介 */
  profile?: string;
  /** 通过地图获取医院名称 */
  amapName: string;
  /** 医院地址 */
  address: string;
  /** 经度 */
  longitude: string;
  /** 纬度 */
  latitude: string;
  /** 营业执照 */
  businessLicense: string;
  /** 地区 code */
  placeCode: string;
  /** 省市区 */
  district: string;
  /** 医院 id */
  hospitalId: number;
}

/** 存储医院列表信息 用户所处的医院信息 */
export enum Hospital {
  /** 当前用户可用医院列表 */
  HOSPITAL_LIST = 'HOSPITAL_LIST',
  /** 曾经登录过的用户最后一次登录的医院列表 */
  NOW_USER_HOSPITAL_INFO_ARR = 'NOW_USER_HOSPITAL_INFO_ARR',
  /** 用户当前所处的医院信息 */
  NOW_USER_HOSPITAL_INFO = 'NOW_USER_HOSPITAL_INFO',
}
/** 医院数组单个对象类型 */
export interface HospitalItem {
  hospitalId: number;
  hospitalName: string;
}
