import { createApp } from 'vue';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
import Antd from 'ant-design-vue';
import { createPinia } from 'pinia';
import { registerSvg } from '@/assets/img/icons';
import { useIcons } from '@/config/antd-icon';
import DictData from '@/config/directive/dict-data';
import Editor from '@/components/common/Editor.vue';
import { getToken } from '@/lib/api';
import { state as globalState, service as globalService } from '@/modules/global';
import SvgIcon from '@/components/common/SvgIcon.vue';
import { usePermissionStore } from '@/modules/permission';
import directive from '@/config/directive/index';
import NProgress from 'nprogress';
import components from '@/tools/register-global-components';
import print from 'vue3-print-nb';
import { setStorageGlobalSetting } from '@neuton/cloud-storage';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import QuestionAnswer from 'questionary-answer-lib';
import { changeConfig, serverConfig } from './config';
import router from './config/router';
import '@/config/lib-style';
import '@/assets/less/index.less';
import '@/config/chart';
import App from './App.vue';

const pinia = createPinia();

// @ts-ignore
changeConfig(process.env.VUE_APP_Environment);

const app = createApp(App);
app.use(pinia);

QuestionAnswer.init({ apiSrc: serverConfig?.apiSrc ?? axios.defaults.baseURL });

const packageInfo = require('../package.json');

app.use(QuestionAnswer);

if (process.env.VUE_APP_Environment !== 'development') {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_Environment,
    dsn: 'https://c42058e6fdf1457d85c179841cf86d35@sentry.vansunscience.com/16',
    logErrors: true,
    integrations: [
      // https://github.com/rrweb-io/rrweb/blob/master/guide.zh_CN.md
      new SentryRRWeb({
        checkoutEveryNth: 100,
        checkoutEveryNms: 15 * 60 * 1000,
        maskAllInputs: false,
        sampling: {
          scroll: 150,
        },
      }),
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    trackComponents: true,
    release: `${packageInfo.name}@${packageInfo.version}`,
  });
}

/** 注册svg组件 */
registerSvg(app);

app.component(SvgIcon.name, SvgIcon);
app.component('Editor', Editor);

const { generateRoutes } = usePermissionStore();

NProgress.configure({ showSpinner: false });

// 云存储配置
setStorageGlobalSetting({
  env: process.env.VUE_APP_Environment === 'production' ? 'prod' : 'test',
  baseUrl: serverConfig.apiSrc, // 预签名服务器地址
  projectKey: 'saas',
  handleToken: () => localStorage.getItem('Authorization') ?? '',
});

dayjs.extend(duration);

const isInexcludePage = toPath => {
  let isIn = false;
  globalState.excludePage.forEach(value => {
    if (toPath.indexOf(value) !== -1) {
      isIn = true;
    }
  });
  return isIn;
};
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getToken()) {
    if (globalState.userInfo.roles.length === 0) {
      /** 判断当前用户是否已拉取完user_info信息 */
      globalService
        .getUserInfo()
        .then(() => {
          generateRoutes().then(() => {
            if (to.matched.length === 0) {
              router.push(to.fullPath);
            }
            next();
          });
        })
        .catch(err => {
          globalService.logout().then(() => {
            next({ path: '/login' });
          });
        });
    } else {
      next();
    }
  } else if (isInexcludePage(to.path)) {
    next();
  } else {
    next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
    NProgress.done();
  }
});

DictData.install(app);
useIcons(app);
app.use(Antd);
app.use(router);
app.use(print);
app.use(directive);
app.use(components);
app.mount('#app');
